<template>
  <div class="calssTicket-tab">
    <PageTitle
      title=""
      btn="新增點數卡"
      btn2="匯出"
      @btnClick="pointCardDialog = true"
      @btn2Click="dialog.export = true"
    />
    <FiltersContainer>
      <BaseElInput
        v-model="search.code"
        clearable
        placeholder="搜尋點數卡編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
      <BaseElInput
        v-model="search.name"
        clearable
        placeholder="搜尋點數卡名稱"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>

    <BaseTable
      v-loading="loading.table"
      :data="displayData"
      empty-text="暫無數據"
      :row-style="{ cursor: 'pointer' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="code" label="點數卡編號" align="center" fixed="left" />
      <BaseElTableColumn prop="name" label="點數卡名稱" align="center" fixed="left">
        <template slot-scope="scope">
          <LinkItem :to="{ name: 'PointCardSetting', query: { name: scope.row.name } }">{{
            scope.row.name
          }}</LinkItem>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="status" label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="selectStatus(scope.row.status, 'tagType')">
            {{ selectStatus(scope.row.status, 'label') }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="amount" label="餘額/總次數" align="center" />
      <BaseElTableColumn prop="price" label="價格" align="center" />
      <BaseElTableColumn prop="createdAt" label="建立時間" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="exp" label="有效期限" align="center" />
      <BaseElTableColumn prop="origin" label="來源" align="center">
        <template slot-scope="scope">
          {{ selectOrigin(scope.row.origin, 'label') }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" align="center">
        <template slot-scope="scope">
          <TableEditBtnGroup
            editBtn="詳情"
            deleteBtn="作廢"
            :hideDelete="scope.row.status !== 'available'"
            @edit="findPointCard(scope.row)"
            @delete=";(deleteDialog = true), (selectRow = scope.row)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="tableOptions.page"
      :pageLimit="tableOptions.pageLimit"
      :total="tableDataCount"
      @pageChange="refresh(false)"
    />
    <CreatePointCardDialog
      v-if="pointCardDialog"
      :member="member"
      @close="pointCardDialog = false"
      @refresh="refresh"
    />
    <PointCardRecordDetail
      :show="showDrawer"
      :selectPointCard="selectRow"
      @close="closeDrawer"
      @cancel="deleteDialog = true"
      @refresh="refresh"
    />
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="作廢"
      @close="deleteDialog = false"
      @delete="voidPointCard(), (deleteDialog = false)"
    />

    <ExportOptionsDialog
      v-if="dialog.export"
      @close="dialog.export = false"
      @export="prepareExport"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-computed-properties-in-data */
import EmptyBlock from '@/components/EmptyBlock.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import PointCardRecordDetail from '@/views/PointCard/components/PointCardRecordDetail.vue'
import {
  GetPointCardRecord,
  GetPointCardRecordCount,
  FindPointCardRecord,
  VoidPointCardRecord,
} from '@/api/pointCard'
import LinkItem from '@/components/LinkItem.vue'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import CreatePointCardDialog from '@/components/Dialog/CreatePointCardDialog.vue'
import { get, map } from 'lodash'
import { pointCardStatusConfig, pointCardOriginConfig } from '@/config/pointCard'
import { useShop } from '@/use/shop'
import { onMounted, onActivated, reactive, computed, ref, defineComponent } from 'vue'
import { useTable } from '@/use/table'

export default defineComponent({
  name: 'PointCardTab',
  components: {
    DeleteDialog,
    EmptyBlock,
    ExportOptionsDialog,
    PointCardRecordDetail,
    LinkItem,
    CreatePointCardDialog,
  },
  props: ['member'],
  setup(props, { emit }) {
    const {
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      pageStartIndex,
      fetchData,
      fetchDataCount,
      dateFormat,
    } = useTable()
    const { shopId } = useShop()
    const dialog = reactive({
      export: false,
    })
    const pointCardDialog = ref(false)
    const showDrawer = ref(false)
    const selectRow = ref(null)
    const deleteDialog = ref(false)
    const search = reactive({
      code: '',
      name: '',
    })
    const displayData = computed(() => {
      return map(tableData.value, (item) => {
        const status = get(item, 'status')
        const remainAmountName = selectStatus(status, 'amount')
        const remainAmount = item[remainAmountName]
        const amount = item.totalAmount === null ? '無上限' : `${remainAmount}/${item.totalAmount}`
        const exp = item.expiredAt === null ? '無期限' : dateFormat(item.expiredAt)
        return {
          ...item,
          amount,
          exp,
        }
      })
    })
    const selectStatus = (status, attr) => {
      return get(pointCardStatusConfig[status], attr)
    }
    const selectOrigin = (origin, attr) => {
      return get(pointCardOriginConfig[origin], attr)
    }
    const closeDrawer = () => {
      showDrawer.value = false
      selectRow.value = null
    }
    const voidPointCard = async () => {
      const [res, err] = await VoidPointCardRecord({
        shopId: shopId.value,
        pointCardRecordId: get(selectRow, 'value.id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      window.$message.success('作廢成功')
      await refresh()
    }
    const findPointCard = async (row) => {
      selectRow.value = row
      await findPointCardRecord()
      showDrawer.value = true
    }
    const findPointCardRecord = async () => {
      const [res, err] = await FindPointCardRecord({
        shopId: shopId.value,
        id: get(selectRow.value, 'id'),
      })
      if (err) {
        window.$message.error(err)
        return
      }
      selectRow.value = res
    }
    const prepareExport = async ({ all, range }) => {
      // TODO: 移除舊的匯出功能
    }
    const getTableData = async () => {
      const payload = {
        shopId: shopId.value,
        start: pageStartIndex.value,
        limit: tableOptions.pageLimit,
        code: search.code || undefined,
        MemberId: props.member ? props.member.id : undefined,
        name: search.name || undefined,
      }
      await Promise.allSettled([
        fetchData(GetPointCardRecord, payload),
        fetchDataCount(GetPointCardRecordCount, payload),
      ])
    }
    const refresh = async (resetPage = false) => {
      if (resetPage) tableOptions.page = 1
      loading.table = true
      await getTableData()
      if (selectRow.value) await findPointCardRecord()
      loading.table = false
    }
    onMounted(async () => {
      await refresh()
    })
    onActivated(async () => {
      await refresh()
    })
    return {
      prepareExport,
      dialog,
      get,
      selectStatus,
      selectOrigin,
      tableData,
      tableOptions,
      tableDataCount,
      loading,
      search,
      refresh,
      displayData,
      dateFormat,
      findPointCard,
      deleteDialog,
      showDrawer,
      voidPointCard,
      selectRow,
      closeDrawer,
      pointCardDialog,
    }
  },
})
</script>

<style scoped lang="scss"></style>
