<template>
  <div class="sales-record-list">
    <PageTitle title="" btn="新增紀錄" @btnClick="goCreate" />

    <FiltersContainer>
      <BaseElInput
        v-model="search.orderNo"
        clearable
        placeholder="輸入銷售紀錄編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i
          slot="suffix"
          class="el-input__icon el-icon-search"
          @click="refresh(true)"
        />
      </BaseElInput>
    </FiltersContainer>

    <section>
      <BaseTable
        v-loading="loading"
        :data="salesRecordList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn
          prop="orderNo"
          label="銷售紀錄編號"
          fixed="left"
          width="200"
          align="center"
        />
        <BaseElTableColumn
          prop="identity"
          label="姓名"
          width="100"
          align="center"
        >
          <template slot-scope="scope">
            {{
              scope.row.identity
                ? identityTrans(scope.row.identity)
                : scope.row.Member
                  ? scope.row.Member.UserInfo.name
                  : '非會員'
            }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="identity"
          label="電話號碼"
          width="120"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.phone }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="totalItemsPrice" label="總額" align="center" />
        <BaseElTableColumn
          prop="totalDiscountPrice"
          label="已折金額"
          align="center"
        />
        <BaseElTableColumn
          prop="totalPaymentPrice"
          label="已付金額"
          align="center"
        />
        <BaseElTableColumn prop="status" label="狀態" align="center">
          <template slot-scope="scope">
            <Tag :type="tagType(scope.row.status)">
              {{ statusTrans(scope.row.status) }}
            </Tag>
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          v-if="useStorePermission"
          prop="Branch"
          label="操作門市"
          align="center"
        >
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || '-' }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn
          prop="createdAt"
          label="建立時間"
          width="115"
          align="center"
        >
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn label="操作" fixed="right" width="120" align="center">
          <template slot-scope="scope">
            <TableSettingIconButton @click="openRecorDetail(scope.row)" />
          </template>
        </BaseElTableColumn>
      </BaseTable>
      <Pagination
        :curPage.sync="tableOptions.page"
        :pageLimit="tableOptions.pageLimit"
        :total="salesRecordCount"
        @pageChange="refresh(false)"
      />
    </section>

    <!-- Dialog -->
    <SalesRecordDetail
      v-if="showDialog"
      :selectedTarget="selectRecord"
      :useShopRebate="useShopRebate"
      @open-record-note-dialog="showRecordNoteDialog = true"
      @close-sales-record-dialog="showDialog = false"
      @show-password-dialog="
        ;(showRecordDialog = false),
          config.useInvalidPassword
            ? (passwordDialog = true)
            : (alertDialog = true)
      "
    />

    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="作廢"
      @close="deleteDialog = false"
      @delete="cancelSalesRecord(), (deleteDialog = false)"
    />

    <SalesRecordDetailDrawer
      v-if="modal.detail"
      :data="selectRow"
      @close="modal.detail = false"
      @refresh="refresh"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { checkUserFeature } from '@/store/modules/permission'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import SalesRecordDetail from './components/SalesRecordDetail.vue'
import EmptyBlock from '@/components/EmptyBlock.vue'
import TableSettingIconButton from '@/components/Button/TableSettingIconButton.vue'
import {
  GetSalesRecord,
  GetSalesRecordCount,
  CancelSalesRecord,
  FindSalesRecord,
  GetSalesConfig,
  UpdateSalesRecord,
} from '@/api/sales'
import MixinFunc from '@/components/MixinFunc.vue'
// Utils
import { pageStartIndex } from '@/utils/table'
import { dialogTitle } from '@/utils/dialog'
import { defineComponent, provide, reactive, ref } from 'vue'
import SalesRecordDetailDrawer from '@/components/SalesRecordDetailDrawer.vue'
import { apiFormatAdaptor } from '@/utils/api'
import { useShop } from '@/use/shop'
import { get } from 'lodash'
import { usePermissions } from '@/use/permissions'

export default defineComponent({
  name: 'SalesRecordTab',
  components: {
    DeleteDialog,
    EmptyBlock,
    SalesRecordDetail,
    TableSettingIconButton,
    SalesRecordDetailDrawer,
  },
  mixins: [MixinFunc],
  props: {
    member: Object,
    useShopRebate: Boolean,
  },
  setup (props, { emit }) {
    const selectRow = ref(null)
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const config = ref({})
    const search = reactive({
      orderNo: null,
    })
    const modal = reactive({
      detail: false,
    })

    const openRecorDetail = async (row) => {
      const [res, err] = await apiFormatAdaptor(FindSalesRecord)({
        shopId: shopId.value,
        id: row.id,
      })
      if (err) {
        window.$message.error(err)
        return
      }

      modal.detail = true
      selectRow.value = res
    }

    provide('configData', config)

    return { modal, selectRow, openRecorDetail, config, checkAction, search }
  },
  data: () => ({
    loading: false,
    phoneSearch: '',
    // Dialog
    alertDialog: false,
    passwordDialog: false,
    password: '',
    showDialog: false,
    dialogType: 'create',
    deleteDialog: false,
    selectRecord: {},
    selectUnitRecord: {},
    salesRecordCount: 0,
    tableOptions: {
      page: 1,
      pageLimit: 10,
    },
    shopRebate: {},
    salesRecordList: [],
    showRecordNoteDialog: false,
    recordNote: '',
  }),
  computed: {
    ...mapGetters(['shop', 'userPlanFeature', 'userFeatures']),

    pageStartIndex () {
      return pageStartIndex(this.tableOptions.page, this.tableOptions.pageLimit)
    },
    dialogTitle () {
      return dialogTitle(this.dialogType, {
        create: '新增銷售紀錄',
        update: '更新銷售紀錄',
      })
    },
    useStorePermission () {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.branch.adminUseStore',
      )
    },
  },

  async mounted () {
    await this.getSalseConfig()
    await this.refresh()
  },

  methods: {
    tagType (val) {
      let type = 'info'
      if (val === 'complete') type = 'action'
      if (val === 'invalid') type = 'danger'
      return type
    },
    //= > 狀態翻譯
    statusTrans (text) {
      const dict = {
        complete: '已完成',
        invalid: '已作廢',
      }
      return dict[text]
    },

    //= > 身分翻譯
    identityTrans (text) {
      const dict = {
        frequenter: '熟客',
        platform: '平台戶',
        passerby: '過路客',
        foreigner: '外國客',
        bookinger: '預約客',
        other: '其他',
      }
      return dict[text]
    },

    unitRecord () {
      const salesItem = this.selectRecord.SalesRecordItems
      const unitRecord = {}

      salesItem.forEach((item) => {
        // 如果有服務人員
        if (item.SalesUnit) {
          const name = item.SalesUnit.name
          if (unitRecord[name]) {
            unitRecord[name].items.push(item)
          } else {
            unitRecord[name] = { name, items: [] }
            unitRecord[name].items.push(item)
          }
        }

        if (!item.SalesUnit) {
          const name = 'nullUnit'
          if (unitRecord[name]) {
            unitRecord[name].items.push(item)
          } else {
            unitRecord[name] = { name, items: [] }
            unitRecord[name].items.push(item)
          }
        }
      })
      this.selectUnitRecord = unitRecord
    },

    async getSalseConfig () {
      try {
        const res = await GetSalesConfig({ shopId: this.shop })
        this.config = res
      } catch (error) {
        console.log(error)
        this.$message.error(error || error.message)
      }
    },

    async findSalesRecord () {
      try {
        const res = await FindSalesRecord({
          shopId: this.shop,
          id: this.selectRow.id,
        })
        this.selectRecord = res
        this.recordNote = this.selectRecord.note
        this.unitRecord()
        this.selectRow = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    async refresh (search = false) {
      if (search) this.tableOptions.page = 1
      if (!this.member.UserInfo.phone) return
      this.loading = true
      await Promise.all([this.getSalseRecord(), this.getSalesRecordCount()])

      if (get(this.selectRow, 'id')) {
        await this.findSalesRecord()
      }

      this.loading = false
    },

    openDialog (type) {
      this.dialogType = type
      this.showDialog = true
    },

    //= > 取得銷售紀錄
    async getSalseRecord () {
      const startIndex = this.pageStartIndex
      const limit = this.tableOptions.pageLimit
      try {
        const res = await GetSalesRecord({
          shopId: this.shop,
          start: startIndex,
          orderNo: this.search.orderNo === '' ? undefined : this.search.orderNo,
          // phone: this.member.UserInfo.phone,
          MemberId: this.member.id,
          limit,
        })
        this.salesRecordList = res
      } catch (error) {
        this.$message.error(error)
      }
    },

    //= > 取得銷售紀錄總數
    async getSalesRecordCount () {
      try {
        const res = await GetSalesRecordCount({
          shopId: this.shop,
          MemberId: this.member.id,
          orderNo: this.search.orderNo === '' ? undefined : this.search.orderNo,
          // phone: this.member.UserInfo.phone,
        })

        this.salesRecordCount = res
      } catch (error) {
        this.$message.error(error)
      }
    },

    async cancelSalesRecord () {
      try {
        if (this.password === '') {
          this.$message.warning('請輸入密碼 !')
          return
        }
        await CancelSalesRecord({
          shopId: this.shop,
          id: this.selectRow.id,
          password: this.password,
        })
        await this.refresh()
        this.$message.success('已作廢銷售紀錄 !')
        this.passwordDialog = false
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    goCreate () {
      const useSalesPOS = this.checkAction('adminView.salesPos.enabled')
      const useOldSalesRecord = this.checkAction('adminView.salesRecord.enabled')

      if (useOldSalesRecord) {
        this.$router.push(`/sales/sales-record-create/${this.member.id}/member`)
      }

      if (useSalesPOS) {
        this.$router.push({
          name: 'SalesPOS',
          query: {
            memberId: this.member.id,
          },
        })
      }
    },
    async updateSalesRecord () {
      try {
        const record = await UpdateSalesRecord({
          shopId: this.shop,
          id: this.selectRow.id,
          note: this.recordNote,
        })
        this.selectRow.note = record.note
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    async updateSaleRecordNote () {
      this.showRecordNoteDialog = false
      await this.updateSalesRecord()
      await this.findSalesRecord()
    },
  },
})
</script>

<style scoped lang="scss">
.unit-block {
  margin-bottom: 25px;
  .unit-name {
    background: #e7e7f3;
    padding: 5px 10px;
    margin-bottom: 8px;
  }

  .record-item {
    margin-bottom: 10px;

    .item-name {
      margin-right: 10px;
    }

    .item-quantity {
      flex-shrink: 0;
      font-weight: 500;
    }
  }
}

.payment {
  margin: 15px 0;
  .title {
    font-size: 21px;
  }
}

.dialog-title {
  @apply text-lg font-bold text-primary-100;
}

.dialog-content {
  text-align: center;
  font-family: Noto Sans TC;
  font-style: normal;
  font-weight: 500;
  font-size: 18px;
  line-height: 28px;
}

.dialog-hr {
  position: absolute;
  left: 0;
  right: 0;
  top: 55px;
  // border: solid 0.5px $black-lighten-3;
}

.price {
  font-weight: 500;
}
</style>
