<template>
  <div class="calssTicket-tab">
    <PageTitle
      title=""
      btn="新增堂票"
      btn2="匯出"
      @btnClick="classTicketDialog = true"
      @btn2Click="dialog.export = true"
    />
    <FiltersContainer>
      <BaseElInput
        v-model="search.code"
        clearable
        placeholder="搜尋堂票編號"
        @keypress.enter.native="refresh(true)"
        @clear="refresh(true)"
      >
        <i slot="prefix" class="el-input__icon el-icon-search" @click="refresh(true)" />
      </BaseElInput>
    </FiltersContainer>

    <BaseTable
      v-loading="loading"
      :data="classTicketRecordList"
      empty-text="暫無數據"
      :row-style="{ cursor: 'pointer' }"
    >
      <EmptyBlock slot="empty" />
      <BaseElTableColumn prop="code" label="堂票編號" width="115" align="center" fixed="left" />
      <BaseElTableColumn prop="name" label="堂票名稱" width="115" align="center" fixed="left">
        <template slot-scope="scope">
          <LinkItem :to="{ name: 'ClassTicketSetting', query: { name: scope.row.name } }">{{
            scope.row.name
          }}</LinkItem>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="status" label="狀態" align="center">
        <template slot-scope="scope">
          <Tag :type="classTicketTagType(scope.row.status)">
            {{ classTicketStatusLabel(scope.row) }}
          </Tag>
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="availableUseTimes" label="剩餘張數" align="center" />
      <BaseElTableColumn prop="availableTimes" label="總張數" align="center" />
      <BaseElTableColumn prop="origin" label="新增來源" width="120" align="center">
        <template slot-scope="scope">
          {{ mapOrigin(scope.row.origin) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn v-if="false" prop="Branch" label="操作門市" align="center">
        <template slot-scope="scope">
          {{ scope.row.Branch?.name || '-' }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="price" label="價格" align="center" />
      <BaseElTableColumn prop="exp" label="有效日期" width="120" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.exp) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="note" label="備註" align="center" show-overflow-tooltip>
        <template slot-scope="scope">
          {{ scope.row.note || '-' }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn prop="createdAt" label="建立時間" width="120" align="center">
        <template slot-scope="scope">
          {{ dateFormat(scope.row.createdAt) }}
        </template>
      </BaseElTableColumn>
      <BaseElTableColumn label="操作" fixed="right" align="center" width="120">
        <template slot-scope="scope">
          <TableEditBtnGroup
            hideDelete
            :hideCopy="false"
            copyBtn="詳情"
            @copy="findClassTicket(scope.row)"
            @edit=";(selectRow = scope.row), (classTicketRecordDialog = true)"
          />
        </template>
      </BaseElTableColumn>
    </BaseTable>

    <Pagination
      :curPage.sync="classTicketRecordTableOptions.page"
      :pageLimit="classTicketRecordTableOptions.pageLimit"
      :total="classTicketRecordCount"
      @pageChange="refresh(false)"
    />

    <!-- 新增堂票 -->
    <el-dialog
      title="新增堂票"
      :visible.sync="classTicketDialog"
      :close-on-click-modal="false"
      @close="resetClassTicketForm"
    >
      <BaseElForm
        ref="classTicketForm"
        :model="classTicketForm"
        :rules="classTicketFormRules"
        label-position="top"
        label-width="100px"
      >
        <BaseElFormItem label="堂票" prop="classTicket">
          <ClassTicketSearch :model.sync="classTicketForm.classTicket" objKey="id" />
        </BaseElFormItem>
        <BaseElFormItem label="組數" prop="quantity">
          <BaseElInput v-model="classTicketForm.quantity" placeholder="請輸入數量" />
        </BaseElFormItem>
        <BaseElFormItem label="單價" prop="price">
          <BaseElInput v-model="classTicketForm.price" type="number" placeholder="請輸入單價" />
        </BaseElFormItem>
        <BaseElFormItem label="備註" prop="note">
          <BaseElInput v-model="classTicketForm.note" placeholder="請輸入" />
        </BaseElFormItem>
      </BaseElForm>
      <div slot="footer">
        <BaseElButton plain @click="classTicketDialog = false">返回</BaseElButton>
        <BaseElButton type="primary" @click="deBounce">確認</BaseElButton>
      </div>
    </el-dialog>

    <!-- 堂票使用紀錄 -->
    <EditMemberClassTicketRecordModal
      v-if="classTicketRecordDialog"
      :record="selectRow"
      @close="classTicketRecordDialog = false"
      @refresh="refresh"
    />
    <!-- <el-dialog
      :close-on-click-modal="false"
      :visible.sync="classTicketRecordDialog"
      @close="resetForm('useTicketForm')"
    >
      <template slot="title">
        <div style="padding-right: 30px">
          {{ `使用堂票紀錄 - ${classTicketForm.name}` }}
        </div>
      </template>
      <BaseElForm
        ref="classTicketForm"
        :model="classTicketForm"
        :rules="classTicketFormRules"
        label-position="top"
        label-width="100px"
      >
        <BaseElFormItem
          label="使用堂票"
          :rules="[isDigit(), noEmpty(), useRange()]"
          prop="use"
        >
          <div class="flex">
            <BaseElInput
              v-model="classTicketForm.use"
              style="width: 150px; margin-right: 20px"
              placeholder="請輸入次數"
            />
            <BaseElButton
              ref="useTicketBtn"
              type="primary"
              @click="useClassTicket"
            >
              確認
            </BaseElButton>
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <BaseElForm
        ref="cancelTicketForm"
        :model="classTicketForm"
        :rules="classTicketFormRules"
        label-position="top"
        label-width="100px"
      >
        <BaseElFormItem
          label="取消使用"
          :rules="[isDigit(), noEmpty(), cancelRange()]"
          prop="cancel"
        >
          <div class="flex">
            <BaseElInput
              v-model="classTicketForm.cancel"
              style="width: 150px; margin-right: 20px"
              placeholder="請輸入次數"
            />
            <BaseElButton
              ref="cancelTicketBtn"
              type="primary"
              @click="cancelUseClassTicket"
            >
              確認
            </BaseElButton>
          </div>
        </BaseElFormItem>
      </BaseElForm>

      <hr>

      <div class="use-history-container">
        <div v-if="classTicketUseList.length === 0">暫無使用紀錄</div>
        <div style="margin-top: 10.8px">剩餘使用次數: {{ availableTimes }}</div>
        <div
          v-for="item in classTicketUseList"
          v-show="classTicketUseList.length"
          :key="`cancel-${item.id}`"
          class="flex-between"
        >
          <template v-if="!item.isCancelType">
            <div>使用次數: {{ item.times }}</div>
            <div v-if="false">XX門市</div>
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
          <template v-if="item.isCancelType">
            <div>取消使用次數: {{ Math.abs(item.times) }}</div>
            <div v-if="false">XX門市</div>
            <div>{{ dateFormat(item.createdAt) }}</div>
          </template>
        </div>
      </div>
    </el-dialog> -->
    <ClassTicketRecordDetail
      :show="showDrawer"
      :selectClassTicket="selectRow"
      :classTicketRecordSerials="classTicketRecordSerials"
      @close="closeDrawer"
      @cancel="deleteDialog = true"
      @refresh="refresh"
    />
    <DeleteDialog
      v-if="deleteDialog"
      title="提醒"
      content="作廢後將無法復原，確定要作廢？"
      width="40%"
      btnString="作廢"
      @close="deleteDialog = false"
      @delete="cancelClassTicketRecord"
    />

    <ExportOptionsDialog
      v-if="dialog.export"
      @close="dialog.export = false"
      @export="prepareExport"
    />
    <ExportDialog
      v-if="exportState.modal"
      title="匯出堂票紀錄"
      :inProgress="exportState.exportting"
      :isError="exportState.error"
      :content="exportState.content"
      :percentage="totalPercentege"
      @close="onExportClose"
    />
  </div>
</template>

<script>
/* eslint-disable vue/no-computed-properties-in-data */
import EmptyBlock from '@/components/EmptyBlock.vue'
import ClassTicketSearch from '@/components/Search/ClassTicketSearch.vue'
import DeleteDialog from '@/components/Dialog/DeleteDialog'
import ClassTicketRecordDetail from '@/views/ClassTicket/components/ClassTicketRecordDetail.vue'
import {
  CreateClassTicketRecord,
  GetClassTicketRecord,
  GetClassTicketRecordCount,
  GetClassTicketUseRecord,
  GetClassTicketUseRecordCount,
  CancelClassTicketRecord,
  GetClassTicketRecordSerial,
  GetClassTicketRecordSerialDetail,
  GetClassTicketRecordSerialCount,
  GetClassTicketRecordSerialOld,
} from '@/api/classTicket'
import EditMemberClassTicketRecordModal from './components/EditMemberClassTicketRecordModal.vue'
import LinkItem from '@/components/LinkItem.vue'
import debounce from 'lodash.debounce'
import formUtils from '@/utils/form'
import { pageStartIndex } from '@/utils/table'
import { noEmptyRules, isDigitRules, rangeRules } from '@/validation/index'
import ExportOptionsDialog from '@/components/Dialog/ExportOptionsDialog.vue'
import ExportDialog from '@/components/Dialog/ExportDialog.vue'
import MixinFunc from '@/components/MixinFunc.vue'
import { checkUserFeature } from '@/store/modules/permission'
import { mapGetters } from 'vuex'
import { get, map, join, find } from 'lodash'
import {
  classTicketRecordOriginConfig,
  classTicketUseRecordOriginConfig,
  classTicketUseRecordTypeConfig,
} from '@/config/classTicket'

import { getAllDataFromApi } from '@/utils/helper'
import { ExportMoreSheetExcel } from '@/utils/excel'
import { formatDate } from '@/utils/date'

import { useExport } from '@/use/export'
import { useShop } from '@/use/shop'
import { reactive } from 'vue'
import dayjs from '@/lib/dayjs'
import { useClassTicket } from '@/use/classTicket'

export default {
  name: 'ClassTicketTab',
  components: {
    ClassTicketSearch,
    DeleteDialog,
    EmptyBlock,
    ExportOptionsDialog,
    ExportDialog,
    EditMemberClassTicketRecordModal,
    ClassTicketRecordDetail,
    LinkItem,
  },
  mixins: [MixinFunc],
  props: ['member'],
  setup(props, { emit }) {
    const {
      exportState,
      resetExport,
      getExportDataOld,
      totalDataCount,
      totalPercentege,
      curTotalDataCount,
    } = useExport()
    const { classTicketStatusLabel } = useClassTicket()
    const { shopId } = useShop()
    const dialog = reactive({
      export: false,
    })

    const mapOrigin = (key) => {
      return get(classTicketRecordOriginConfig, `${key}.label`, '-')
    }
    const onExportClose = () => {
      resetExport()
    }
    const formatAddRecordData = (item) => {
      return {
        堂票編號: item.code,
        姓名: get(item, 'Member.UserInfo.name') || '非會員',
        堂票名稱: item.name,
        狀態: classTicketStatusLabel(item),
        可用張數: item.availableTimes,
        已用張數: item.usedTimes,
        已贈張數: item.giveTimes,
        價格: item.price,
        新增來源: mapOrigin(item.origin),
        有效期限: item.isExp ? '有' : '無',
        有效日期: item.exp ? formatDate(item.exp) : '-',
        備註: item.note,
        建立時間: formatDate(item.createdAt),
      }
    }
    const formatAddRecordSerialData = (item) => {
      return {
        堂票序號編號: get(item, 'code'),
        堂票序號狀態: classTicketStatusLabel(item),
        會員姓名: get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        堂票編號: get(item, 'ClassTicketRecord.code'),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票狀態: classTicketStatusLabel(get(item, 'ClassTicketRecord')),
        有效期限: get(item, 'ClassTicketRecord.isExp') ? '有' : '無',
        有效日期: get(item, 'ClassTicketRecord.exp')
          ? formatDate(get(item, 'ClassTicketRecord.exp'))
          : '-',
        新增來源: mapOrigin(get(item, 'ClassTicketRecord.origin')),
        是否履約: get(item, 'TicketPaymentPromise.ticketPromiseRecordId') ? '是' : '否',
        // 是否已送履約核銷: '-',
        建立時間: formatDate(item.createdAt),
      }
    }
    const formatUseRecordData = (item) => {
      return {
        使用時間: formatDate(item.createdAt),
        堂票名稱: get(item, 'ClassTicketRecord.name'),
        堂票編號: get(item, 'ClassTicketRecord.code'),
        使用來源: get(classTicketUseRecordOriginConfig, `${item.origin}.label`),
        使用門市: get(item, 'Branch.name'),
        會員名稱: get(item, 'ClassTicketRecord.Member.UserInfo.name') || '非會員',
        使用類型: get(classTicketUseRecordTypeConfig, `${item.type}.label`),
        '使用/取消': item.isCancelType ? '取消' : '使用',
        '使用/取消次數': Math.abs(item.times),
        堂票序號陣列: join(map(item.ClassTicketRecordSerials, 'code'), ','),
      }
    }
    const prepareExport = async ({ all, range }) => {
      dialog.export = false
      const payload = {
        shopId: shopId.value,
        MemberId: props.member.id,
        createdAtStart: get(range, '[0]') ? dayjs(get(range, '[0]')).toDate() : undefined,
        createdAtEnd: get(range, '[0]') ? dayjs(get(range, '[1]')).toDate() : undefined,
      }
      exportState.modal = true
      exportState.exportting = true
      try {
        const res = await Promise.all([
          GetClassTicketRecordCount(payload),
          GetClassTicketUseRecordCount(payload),
          GetClassTicketRecordSerialCount(payload, { oldInstance: true }),
        ])
        exportState.dataCount = res
        if (!totalDataCount.value) {
          exportState.content = '尚無資料可匯出'
          exportState.error = true
          window.$message.warning('尚無資料可匯出')
          return
        }

        const [recordData, useRecordData, serialData] = await Promise.all([
          getExportDataOld({ stage: 0, fetchAPI: GetClassTicketRecord, payload }),
          getExportDataOld({ stage: 1, fetchAPI: GetClassTicketUseRecord, payload }),
          getExportDataOld({ stage: 2, fetchAPI: GetClassTicketRecordSerialOld, payload }),
        ])

        const exportData = {
          recordData: [],
          useRecordData: [],
          serialData: [],
        }

        for (const subitem of serialData) {
          const row = formatAddRecordSerialData(subitem)
          exportData.serialData.push(row)
        }
        for (const item of recordData) {
          exportData.recordData.push(formatAddRecordData(item))
        }
        for (const item of useRecordData) {
          exportData.useRecordData.push(formatUseRecordData(item))
        }
        const sheets = [
          {
            sheetName: '堂票新增紀錄',
            data: exportData.recordData,
          },
          {
            sheetName: '堂票序號新增紀錄',
            data: exportData.serialData,
          },
          {
            sheetName: '堂票使用紀錄',
            data: exportData.useRecordData,
          },
        ]
        exportState.success = true
        exportState.content = '匯出完成'
        ExportMoreSheetExcel(sheets, '堂票新增紀錄')
      } catch (error) {
        exportState.exportting = false
        exportState.error = true
      }
    }
    return {
      ExportMoreSheetExcel,
      prepareExport,
      onExportClose,
      totalPercentege,
      totalDataCount,
      curTotalDataCount,
      classTicketStatusLabel,
      mapOrigin,
      exportState,
      dialog,
      get,
    }
  },
  data() {
    return {
      loading: false,
      classTicketRecordCount: 0,
      classTicketRecordList: [],
      classTicketUseList: [],
      classTicketRecordTableOptions: {
        page: 1,
        pageLimit: 10,
      },

      selectRow: null,
      classTicketForm: {
        classTicket: [],
        price: '',
        note: '',
        times: 1,
        cancel: '',
        name: '',
        quantity: 1,
        status: '',
        availableUseTimes: 0,
        availableTimes: 0,
        usedTimes: 0,
      },

      classTicketFormRules: {
        classTicket: [noEmptyRules()],
        price: [isDigitRules(), noEmptyRules(), rangeRules()],
        quantity: [noEmptyRules(), rangeRules(1)],
        times: [isDigitRules(), noEmptyRules(), rangeRules(1, this.availableTimes)],
        cancel: [isDigitRules(), noEmptyRules(), rangeRules(1, this.usedTimes)],
      },

      classTicketDialog: false,
      classTicketRecordDialog: false,
      deleteDialog: false,

      search: {
        code: '',
      },

      warning: {
        type: null,
        error: null,
        total: 0,
        success: 0,
        fail: 0,
      },
      processing: {
        task: null,
        detail: null,
        title: '',
        inProgress: false,
        isError: false,
        percentage: 0,
      },
      showAll: false,
      btnLoading: {
        use: false,
        cancel: false,
      },
      showDrawer: false,
      classTicketRecordSerials: {},
    }
  },
  computed: {
    ...mapGetters(['userPlanFeature', 'userFeatures']),
    shop() {
      return this.$store.getters.shop
    },

    classTicketRecordStartIndex() {
      return pageStartIndex(
        this.classTicketRecordTableOptions.page,
        this.classTicketRecordTableOptions.pageLimit,
      )
    },

    useClassTicketFeature() {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.classTicket.page')
    },

    useStorePermission() {
      return checkUserFeature(this.userPlanFeature, this.userFeatures, 'admin.branch.adminUseStore')
    },

    useTicketPermission() {
      return checkUserFeature(
        this.userPlanFeature,
        this.userFeatures,
        'admin.classTicketConfig.clientGive',
      )
    },

    availableTimes() {
      if (!this.selectRow) return 0
      const available = this.selectRow.availableTimes
      const used = this.selectRow.usedTimes
      return available - used
    },
    usedTimes() {
      if (!this.selectRow) return 0
      const used = this.selectRow.usedTimes
      return used
    },
  },
  async mounted() {
    await this.refresh()
  },
  methods: {
    async refresh(search = false) {
      if (search) this.classTicketRecordTableOptions.page = 1
      this.loading = true
      await this.getClassTicketRecord()
      await this.getClassTicketRecordCount()

      if (get(this.selectRow, 'id')) {
        this.selectRow = find(this.classTicketRecordList, { id: get(this.selectRow, 'id') })
      }

      this.loading = false
    },
    resetClassTicketForm() {
      formUtils.resetForm(this.$refs.classTicketForm)
      this.classTicketForm = {
        classTicket: [],
        price: '',
        note: '',
        times: 1,
        name: '',
      }
    },

    resetForm(form) {
      formUtils.resetForm(this.$refs[form])
    },

    classTicketTagType(val) {
      let type = 'info'
      if (val === 'open') type = 'action'
      if (val === 'cancel') type = 'danger'
      if (val === 'overdue') type = 'warning'
      return type
    },

    classTicketIsExpTag(val) {
      let type = 'info'
      // if(! val) type = 'danger'
      if (val) type = 'warning'
      return type
    },

    //= > 取得堂票紀錄
    async getClassTicketRecord() {
      try {
        const res = await GetClassTicketRecord({
          shopId: this.shop,
          start: this.classTicketRecordStartIndex,
          limit: this.classTicketRecordTableOptions.pageLimit,
          code: this.search.code === '' ? undefined : this.search.code,
          MemberId: this.member.id,
        })

        this.classTicketRecordList = res
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    //= > 取得堂票紀錄總數
    async getClassTicketRecordCount() {
      try {
        this.classTicketRecordCount = await GetClassTicketRecordCount({
          shopId: this.shop,
          code: this.search.code === '' ? undefined : this.search.code,
          MemberId: this.member.id,
        })
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },

    deBounce: debounce(
      async function () {
        try {
          if (!(await formUtils.checkForm(this.$refs.classTicketForm))) {
            return
          }
          await CreateClassTicketRecord({
            shopId: this.shop,
            memberId: this.member.id,
            classTicketId: this.classTicketForm.classTicket,
            price: this.classTicketForm.price,
            quantity: this.classTicketForm.quantity,
            note: this.classTicketForm.note === '' ? undefined : this.classTicketForm.note,
          })
          this.$message.success('新增堂票成功')
          await this.refresh()
          this.classTicketDialog = false
          this.resetClassTicketForm()
        } catch (error) {
          console.log(error)
          this.$message.error({
            message: error || error.message,
          })
        }
        this.classTicketDialog = false
      },
      300,
      { leading: true, trailing: false },
    ),

    //= > 作廢堂票紀錄
    async cancelClassTicketRecord() {
      try {
        await CancelClassTicketRecord({
          shopId: this.shop,
          id: this.selectRow.id,
        })

        await this.getClassTicketRecord()
        this.deleteDialog = false
        this.showDrawer = false
        this.$message.success('取消成功')
      } catch (error) {
        console.log(error)
        this.$message.error({
          message: error || error.message,
        })
      }
    },
    //= > 查找堂票
    async findClassTicket(row) {
      this.selectRow = row
      this.getClassTicketSerial()
      this.showDrawer = true
    },
    async getClassTicketSerial() {
      const [res, err] = await GetClassTicketRecordSerialDetail({
        shopId: this.shop,
        id: this.selectRow.id,
      })
      if (err) window.$message.error(err)
      this.classTicketRecordSerials = res
    },
    // ----------- Export -----------
    async getExportData(type) {
      let fetchApi = GetClassTicketRecord
      if (type === 'use') fetchApi = GetClassTicketUseRecord
      const payload = {
        shopId: this.shop,
        start: 0,
        limit: 100,
        search: this.search.all || undefined,
        MemberId: this.member.id || undefined,
      }

      return await getAllDataFromApi(this.exportTotal, fetchApi, payload)
    },
    resetProcessing() {
      this.processing.task = null
      this.processing.title = ''
      this.processing.percentage = 0
      this.processing.isError = false
      this.processing.inProgress = false
    },
    // async prepareExport ({ all, range }) {
    //   console.log('all', all)
    //   console.log('range', range)
    //   this.dialog.export = false
    //   this.resetExport()
    //   // 取得匯出數據數量

    //   const res = await GetClassTicketRecordCount({
    //     shopId: this.shop,
    //     search: this.search.all || undefined,
    //     MemberId: this.member.id || undefined,
    //   })
    //   this.exportTotal = res
    //   if (!this.exportTotal) {
    //     this.$message.warning('沒有資料可以匯出')
    //     return
    //   }
    //   this.showExportDialog = true
    //   this.exportting = true
    //   try {
    //     const addData = await this.getExportData('add')
    //     this.exportData = addData
    //     const useData = await this.getExportData('use')
    //     this.exportUseData = useData
    //     await this.formatExportData()
    //     this.exportting = false
    //   } catch (error) {
    //     console.log(error)
    //     this.exportting = false
    //     this.exportError = true
    //     this.$message.error(error)
    //   }
    // },
    async recordSerial() {
      const [res, err] = await GetClassTicketRecordSerial({
        shopId: this.shop,
        start: 0,
        limit: 100,
        MemberId: this.member.id || undefined,
      })
      if (err) this.$message.error(err)
      return res
    },
    closeDrawer() {
      this.showDrawer = false
      this.selectRow = null
      this.classTicketRecordSerials = {}
    },
  },
}
</script>

<style scoped lang="scss">
.use-history-container {
  @apply grid grid-flow-row gap-[8px];
}
</style>
