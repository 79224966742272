<template>
  <div class="wallet-tab">
    <section v-if="useWalletPermission && useDepositWalletPermission">
      <div v-loading="loading" class="use" style="margin-top: 20px">
        <BaseElForm
          ref="formRef"
          :model="walletRecordForm"
          :rules="formRules"
          label-width="120px"
          label-position="left"
        >
          <BaseElFormItem label="操作" prop="walletAction">
            <BaseElRadioGroup v-model="walletRecordForm.walletAction" @change="onTypeChange">
              <BaseElRadio v-if="useWalletPermission" label="withdraw">使用</BaseElRadio>
              <BaseElRadio v-if="useDepositWalletPermission" label="deposit">儲值</BaseElRadio>
            </BaseElRadioGroup>
          </BaseElFormItem>
          <BaseElFormItem label="金額" prop="coin">
            <BaseElInput v-model="walletRecordForm.coin" />
          </BaseElFormItem>
          <BaseElFormItem label="備註" prop="note">
            <BaseElInput v-model="walletRecordForm.note" />
          </BaseElFormItem>
          <BaseElFormItem>
            <BaseElButton
              plain
              @click="onCancel"
            >
              取消
            </BaseElButton>
            <BaseElButton type="primary" :loading="loading" @click="handleWallet">儲存</BaseElButton>
          </BaseElFormItem>
        </BaseElForm>
      </div>
    </section>

    <!-- <h3 class="table-title">儲值金使用紀錄</h3> -->
    <template v-if="useWalletRecordPermission">
      <SectionTitle title="儲值金使用紀錄" hideBtn />
      <BaseTable
        v-loading="loading"
        :data="walletRecordList"
        empty-text="暫無數據"
      >
        <EmptyBlock slot="empty" />
        <BaseElTableColumn prop="type" label="形式" align="center">
          <template slot-scope="scope">
            {{ convertNoteCode(scope.row.noteCode) }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="amount" label="儲值金" align="center" />
        <BaseElTableColumn prop="note" label="備註" align="center" show-overflow-tooltip>
          <template slot-scope="scope">
            {{ scope.row.note || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn v-if="false" prop="Branch" label="操作門市" align="center">
          <template slot-scope="scope">
            {{ scope.row.Branch?.name || "-" }}
          </template>
        </BaseElTableColumn>
        <BaseElTableColumn prop="createdAt" label="建立時間" width="120" align="center">
          <template slot-scope="scope">
            {{ dateFormat(scope.row.createdAt) }}
          </template>
        </BaseElTableColumn>
      </BaseTable>

      <Pagination
        :curPage.sync="walletRecordTableOptions.page"
        :pageLimit="walletRecordTableOptions.pageLimit"
        :total="walletRecordCount"
        @pageChange="refresh('wallet')"
      />
    </template>
  </div>
</template>

<script>
import { noEmptyRules, isDigitRules, maxNumberRules, rangeRules, minRules } from '@/validation/index'
import {
  GiveDepositWallet,
  UseDepositWallet,
  FindDepositWalletRecord,
  DepositWalletRecordCount,
} from '@/api/member'
import EmptyBlock from '@/components/EmptyBlock.vue'
import formUtils from '@/utils/form'
import MixinFunc from '@/components/MixinFunc.vue'
import { pageStartIndex } from '@/utils/table'
import {
  noteCodeOptions,
} from '@/config/wallet'
import { computed, reactive, ref, onMounted, defineComponent, nextTick, watch } from 'vue'
import { get } from 'lodash'
import { usePermissions } from '@/use/permissions'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'WalletTab',
  components: { EmptyBlock },
  mixins: [MixinFunc],
  props: ['member'],
  setup (props, { emit }) {
    const formRef = ref(null)
    const { shopId } = useShop()
    const { checkAction } = usePermissions()
    const walletRecordForm = reactive({
      walletAction: '',
      note: '',
      coin: '',
    })

    const formRules = computed(() => {
      const rules = {
        coin: [isDigitRules(), noEmptyRules()],
        walletAction: [noEmptyRules()],
      }

      const walletBalance = get(props.member, 'Wallet.balance', 0)

      if (walletRecordForm.walletAction === 'withdraw') {
        if (!walletBalance) {
          rules.coin.push(minRules(0))
          rules.coin.push(maxNumberRules(0))
        } else {
          rules.coin.push(rangeRules(1, walletBalance))
        }
      } else if (walletRecordForm.walletAction === 'deposit') {
        rules.coin.push(minRules(1))
      }
      return rules
    })

    watch(formRules, () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 30)
    })

    const onCancel = () => {
      walletRecordForm.walletAction = null
      walletRecordForm.coin = null
      walletRecordForm.note = null
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 30)
    }

    const onTypeChange = () => {
      setTimeout(() => {
        formUtils.clearValidate(formRef.value)
      }, 30)
    }

    const loading = ref(false)
    const walletRecordCount = ref(0)
    const walletRecordList = ref([])
    const walletRecordTableOptions = reactive({
      page: 1,
      pageLimit: 10,
    })

    const walletRecordStartIndex = computed(() => {
      return pageStartIndex(
        walletRecordTableOptions.page,
        walletRecordTableOptions.pageLimit,
      )
    })

    const useWalletPermission = computed(() => {
      return checkAction('admin.member.adminUseWallet')
    })

    const useDepositWalletPermission = computed(() => {
      return checkAction('admin.member.depositWallet')
    })

    const useWalletRecordPermission = computed(() => {
      return checkAction('admin.member.findWalletRecord')
    })

    const useStorePermission = computed(() => {
      return checkAction('admin.branch.adminUseStore')
    })

    const refresh = async () => {
      loading.value = true
      await findDepositWalletRecord()
      await depositWalletRecordCount()
      loading.value = false
    }

    const walletCoinTagType = (val) => {
      let type = 'info'
      if (val === 'from') type = 'primary'
      if (val === 'to') type = 'warning'
      return type
    }

    const giveDepositWallet = async () => {
      try {
        await GiveDepositWallet({
          shopId: shopId.value,
          id: props.member.id,
          amount: walletRecordForm.coin,
          note: walletRecordForm.note === '' ? undefined : walletRecordForm.note,
        })
        await findDepositWalletRecord()
        window.$message.success('已儲值儲值金!')
        emit('updateWallet')
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const useDepositWallet = async () => {
      try {
        if (!await formUtils.checkForm(formRef.value)) return
        await UseDepositWallet({
          shopId: shopId.value,
          id: props.member.id,
          amount: walletRecordForm.coin,
          note: walletRecordForm.note === '' ? undefined : walletRecordForm.note,
        })

        await findDepositWalletRecord()
        window.$message.success('已使用儲值金!')
        emit('updateWallet')
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const findDepositWalletRecord = async () => {
      try {
        const res = await FindDepositWalletRecord({
          shopId: shopId.value,
          id: props.member.id,
          start: walletRecordStartIndex.value,
          limit: walletRecordTableOptions.pageLimit,
        })
        walletRecordList.value = res
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const depositWalletRecordCount = async () => {
      try {
        const res = await DepositWalletRecordCount({
          shopId: shopId.value,
          id: props.member.id,
        })
        walletRecordCount.value = res
      } catch (error) {
        console.log(error)
        window.$message.error({
          message: error || error.message,
        })
      }
    }

    const handleWallet = async () => {
      loading.value = true
      const isValid = await formUtils.checkForm(formRef.value)
      if (!isValid) {
        loading.value = false
        return
      }
      if (walletRecordForm.walletAction === 'deposit') {
        await giveDepositWallet()
      } else {
        await useDepositWallet()
      }
      loading.value = false
      await nextTick()
      onCancel()
    }

    const convertNoteCode = (noteCode) => {
      if (noteCodeOptions) {
        const item = noteCodeOptions.find(item => item.value === noteCode)
        if (item) return item.label
      }
      return '-'
    }

    onMounted(async () => {
      await refresh()
    })

    return {
      formRef,
      walletRecordForm,
      onCancel,
      onTypeChange,
      formRules,
      loading,
      walletRecordCount,
      walletRecordList,
      walletRecordTableOptions,
      walletRecordStartIndex,
      useWalletPermission,
      useDepositWalletPermission,
      useWalletRecordPermission,
      useStorePermission,
      refresh,
      walletCoinTagType,
      giveDepositWallet,
      useDepositWallet,
      findDepositWalletRecord,
      depositWalletRecordCount,
      handleWallet,
      convertNoteCode,
    }
  },
})
</script>

<style scoped lang="scss"></style>
